<!-- qa问题搜索有结果 -->
<template>
  <div>
    <headBar />
    <searchModel />
    <div class="backGroundBg">
      <div class="con">
        <div class="titleBox" @click="$fun.routerToPage('/questionAndAnswer')">
          {{ $fanyi("帮助中心") }}
        </div>
        <div class="searchConBox">
          <div class="searchBoxRight">
            <h1>検索結果: {{ $route.query.s.replace("+", " ") }}</h1>
            <div class="divider"></div>
            <ul class="answerList">
              <li
                v-for="(searchItem, searchIndex) in $store.state.searchList"
                :key="searchIndex"
              >
                <h2>
                  <span
                    @click="
                      $fun.routerToPage(
                        `/questionAndAnswer?page=${searchItem.router}`
                      )
                    "
                    v-html="searchItem.title"
                  ></span>
                </h2>
                <div class="fontCon">
                  <div v-if="typeof searchItem.content == 'object'">
                    <p
                      v-for="(conIt, conIn) in searchItem.content"
                      :key="conIn"
                    >
                      {{ conIt.title }}
                    </p>
                  </div>
                  <div v-else v-html="searchItem.content"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footbar />
  </div>
</template>
<script>
import headBar from "@/components/headBar/index.vue";
import footbar from "@/components/footBar/index.vue";
import searchModel from "./components/searchModel.vue";
export default {
  data() {
    return {
      page: 1,
      showList: [],
    };
  },
  components: {
    headBar,
    footbar,
    searchModel,
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.backGroundBg {
  background-color: white;
  padding-top: 20px;
  .con {
    margin: 0 auto;
    .titleBox {
      padding: 47px 40px 30px;
      width: max-content;
      display: flex;
      flex-wrap: wrap;
      font-size: 20px;
      * {
        font-size: 20px;
        line-height: 54px;
      }
      .pageNameSpan {
        color: #7a7a7a;
      }
      .van-icon-arrow {
        margin: 0 20px;
        color: #1a289d;
      }
    }
    .searchConBox {
      display: flex;
      .searchBoxRight {
        flex: 1;
        padding: 10px 40px;
        h1 {
          font-size: 32px;
          font-weight: bold;
          line-height: 48px;
        }
        // 分割线
        .divider {
          height: 1px;
          background-color: #d5d5d5;
          margin: 30px 0 50px;
        }
        .answerList {
          h2 {
            margin-bottom: 20px;
            /deep/span {
              font-size: 28px;
              font-weight: 400;
              color: #b4272b;
              line-height: 42x;
              margin-bottom: 10px;
              cursor: pointer;
              &:hover {
                color: black;
              }
            }
          }
          .fontCon {
            margin-bottom: 30px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            p,
            /deep/p,
            /deep/li {
              line-height: 36px;
              font-size: 24px;
              * {
                display: inline;
                font-size: 24px;
                font-weight: 400;
                line-height: 36px;
                color: #404040;
              }
              b {
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}
</style>
